<template>
  <div>
    <div class="d-flex justify-content-between mb-1">
      <div>
        <router-link to="/discounts/create-promocode">
          <b-button variant="primary">
            <span class="align-middle">Add New Promocode</span>
          </b-button>
        </router-link>
      </div>
      <div>
        <router-link to="/discounts/deleted-promocodes">
          <b-button
            variant="danger"
            size="md"
          >
            <span class="align-middle">Show deleted Promocode</span>
          </b-button>
        </router-link>
      </div>
    </div>
    <b-overlay
      :show="loader"
      rounded="sm"
      variant="dark"
      :opacity="0.1"
    >
      <b-card title="Promocodes">
        <b-table
          :responsive="true"
          :busy.sync="isBusy"
          :items="items"
          :fields="fields"
        >
          <template #table-busy>
            <div class="text-center text-success my-2">
              <b-spinner class="align-middle" />
              <strong> Loading...</strong>
            </div>
          </template>
          <template #cell(ID)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(status)="data">
            {{ data.value === 1 ? 'Enable':'Disable' }}
          </template>
          <template #cell(show_on_website)="data">
            {{ data.value === 1 ? 'Yes':'No' }}
          </template>
          <template #cell(actions)="data">
            <div class="d-flex">
              <router-link :to="'/discounts/update-promocode/' + data.item.id">
                <b-button
                  variant="flat-warning"
                  class="btn-icon"
                >
                  <feather-icon icon="EditIcon" />
                </b-button>
              </router-link>
              <b-button
                variant="flat-danger"
                class="btn-icon"
                @click="deletePromocode(data.item)"
              >
                <feather-icon icon="DeleteIcon" />
              </b-button>
            </div>
          </template>
        </b-table>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const discountsModule = createNamespacedHelpers('discounts')

export default {
  data() {
    return {
      isBusy: false,
      loader: false,
      fields: [
        { key: 'ID', label: 'ID' },
        { key: 'code', label: 'code' },
        { key: 'discount', label: 'discount' },
        { key: 'status', label: 'status' },
        { key: 'type', label: 'type' },
        { key: 'show_on_website', label: 'show_on_website' },
        { key: 'actions', label: 'actions' },
      ],
      items: [],
    }
  },
  async mounted() {
    await this.getPromocodes()
  },
  methods: {
    ...discountsModule.mapActions(['SHOW_ALL_PROMOCODE', 'DELETE_PROMOCODE']),

    async getPromocodes() {
      try {
        this.isBusy = true
        this.items = await this.SHOW_ALL_PROMOCODE()
        this.isBusy = false
      } catch (error) {
        this.isBusy = false
      }
    },

    async deletePromocode(item) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete this promocode', {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async value => {
          if (value) {
            try {
              this.loader = true
              const resp = await this.DELETE_PROMOCODE(item.id)
              if (resp) {
                this.items = await this.SHOW_ALL_PROMOCODE()
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Promocode deleted!',
                    icon: 'PlusIcon',
                    variant: 'success',
                    text: 'Promocode has been deleted successfully!',
                  },
                })
              }
              this.loader = false
            } catch (error) {
              this.loader = false
              this.items = []
            }
          }
        })
    },
  },
}
</script>
